<template>
  <section>
    <div class="single-modal">
      <p v-html="board_of">{{board_of}}</p>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <span>Meet the JCI Foundation Board members.</span>
      <el-date-picker v-model="year" type="year" placeholder="year" style="width: 100px;" value-format="yyyy"
        @change="_getBoardOfDirectors">
      </el-date-picker>
    </div>
    <div class="board-list mt-3">
      <el-row :gutter="20">
        <el-col v-for="(v,i) in boardData" :key="i" :span="6">
          <div class="board-card">
            <el-avatar style="width: 80px; height: 80px" :src="v.avatar"></el-avatar>
            <h3>{{v.name}}</h3>
            <p class="m-0">{{v.position}}</p>
            <span>{{v.location}}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="background:#fff;" v-if="!boardData.length">
      <img src="@/assets/images/group/empty1.png" alt="" style="width:250px;margin: 100px auto; display: block"/>
    </div>
    <div class="py-3" style="text-align: right;">
      <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size"
        @current-change="changePage" hide-on-single-page>
      </el-pagination>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        board_of: '',
        boardData: [],
        page: 1,
        size: 12,
        total: 0,
        year: ''
      };
    },

    components: {},

    created() {
      this.year = String(new Date().getFullYear())
      this._getBoardOfDirectors()
    },

    methods: {
      async _getBoardOfDirectors() {
        let res = await this.$http.getBoardOfDirectors({ per_page: 12, page: this.page, year: this.year })
        if (res.status == 200) {
          this.board_of = res.data.board_of
          this.boardData = res.data.director.data
          this.total = res.data.director.total
        }
      },
      changePage(value) {
        this.page = value
        this._getBoardOfDirectors()
      }
    }
  }

</script>
<style lang="scss" scoped>
  .single-modal {
    ::v-deep p {
      color: #666;
      width: 100%;
      /* word-break: break-all; */
      /* word-wrap: break-word; */
    }
  }

  .select-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h3 {
      font-size: 14px;
      color: #333;
      font-weight: Medium;
    }

    ::v-deep .el-button {
      border-radius: 40px;
    }
  }

  .board-card {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #f1f1f1;
    background: #fbfbfb;
    border-radius: 5px;
    margin-bottom: 20px;

    >h3 {
      font-size: 16px;
      margin-top: 30px;
    }

    >p {
      color: #999;
    }

    >span {
      color: #666;
    }
  }
</style>