<template>
  <section>
    <div class="single-modal">
      <h3>Invest in JCI through the JCI Foundation.</h3>
      <div v-html="introduce">
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        introduce: ''
      };
    },

    components: {},

    created() {
      this._getJciFoundation()
    },

    methods: {
      async _getJciFoundation() {
        let res = await this.$http.getJciFoundation()
        if (res.status == 200) {
          this.introduce = res.data
        }
      }
    }
  }

</script>
<style lang="scss" scoped>
  .single-modal {
    width: 100%;

    >h3 {
      color: #333;
      font-size: 14px;
      padding: 10px 0 20px;
      font-weight: 500;
    }

    ::v-deep p {
      color: #666;
      width: 100%;
      /* word-break: break-all; */
      /* word-wrap: break-word; */
    }

    >div {
      width: 100%;
      min-height: 285px;
      border-radius: 5px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>