<template>
  <section>
    <el-row class="d-flex w-50">
      <el-col>
        <el-form ref="form" :model="form">
          <el-form-item>
            <el-input v-model="form.name" placeholder="Filter by name" @keyup.enter.native="page=1,_getDonateRecord()"
              style="width: 224px;" @change="page=1,_getDonateRecord()" clearable>
              <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer"
                @click="page=1,_getDonateRecord()"></i>
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <!-- <el-col :span="8">
        <el-form ref="form" :model="form">
          <el-form-item>
            <el-select v-model="form.region" placeholder="Select an organization">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col> -->
      <el-col>
        <el-form ref="form" :model="form">
          <el-form-item>
            <el-select v-model="form.donor_level_id" @change="page=1,_getDonateRecord()" placeholder="Donor level"
              clearable>
              <el-option v-for="item of level" :key="item.id" :label="item.level_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" tooltip-effect="light">
      <!-- <el-table-column prop="avatar" label="Avatar" width="70">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div class="user-img img-fluid">
              <el-avatar :size="40" :src="scope.row.avatar" @error="true">
                <img src="@/assets/images/public/user.png" />
              </el-avatar>
            </div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column show-overflow-tooltip label="Donor">
        <template slot-scope="scope">
          <span class="m-0">
            {{ scope.row.donor_first_name+' '+scope.row.donor_last_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="donor_country" label="Country" show-overflow-tooltip></el-table-column>
      <el-table-column prop="organization" label="Organization" show-overflow-tooltip></el-table-column>
      <el-table-column prop="donate_to" label="Donate to" show-overflow-tooltip></el-table-column>
      <el-table-column prop="donate_level" label="Donor Level" show-overflow-tooltip></el-table-column>
      <!-- <el-table-column prop="donation_amount" label="Amount(USD)"></el-table-column> -->
      <!-- <el-table-column prop="donation_at" label="Datetime"></el-table-column> -->
    </el-table>
    <div class="py-3" style="text-align: right;">
      <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size"
        @current-change="changePage" hide-on-single-page>
      </el-pagination>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          name: '',
          donor_level_id: ''
        },
        level: [],
        tableData: [],
        page: 1,
        size: 12,
        total: 0
      };
    },

    components: {},

    mounted() {
      this._getDonateRecord();
      this._getDonorLevel();
    },

    methods: {
      async _getDonorLevel() {
        let res = await this.$http.getDonorLevel()
        if (res.status == 200) {
          this.level = res.data.level
        }
      },
      async _getDonateRecord() {
        let res = await this.$http.getDonateRecord({ donated_type: 2, ...this.form, per_page: 12, page: this.page })
        if (res.status == 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      },
      changePage(value) {
        this.page = value
        this._getDonateRecord()
      }
    }
  }

</script>
<style scoped>
  ::v-deep .el-input__inner {
    height: 45px;
    border-radius: 11px;
  }
</style>