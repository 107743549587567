<template>
  <section class="Foundation container970">
    <div class="top-banner">
      <img src="../../../assets/images/public/small_banner.png" alt="" class="small_banner">
      <h3>JCI Foundation</h3>
      <el-button type="primary" size="large" @click="$router.push('/foundation-form')">Make a Donation</el-button>
    </div>
    <el-card style="margin-top: 20px">
      <tab-nav :tabs="true">
        <tab-nav-items :active="active == 'Introduction'" href="#Introduction" title="Introduction" />
        <tab-nav-items :active="active == 'board'" href="#board" title="Board of Directors" />
        <tab-nav-items :active="active == 'level'" href="#level" title="Donor levels" />
        <tab-nav-items :active="active == 'donors'" href="#donors" title="Donors" />
      </tab-nav>

      <tab-content>
        <tab-content-item :active="active == 'Introduction'" id="Introduction">
          <Intro />
        </tab-content-item>
        <tab-content-item :active="active == 'board'" id="board">
          <Board />
        </tab-content-item>
        <tab-content-item :active="active == 'level'" id="level">
          <Level />
        </tab-content-item>
        <tab-content-item :active="active == 'donors'" id="donors">
          <Donors />
        </tab-content-item>
      </tab-content>
    </el-card>
  </section>
</template>

<script>
  import Intro from './components/Introduce'
  import Board from './components/Board'
  import Level from './components/Level'
  import Donors from './components/Donors'
  export default {
    data() {
      return {
        active: "Introduction"

      };
    },

    components: { Intro, Board, Level, Donors },

    created() {
      let type = this.$route.query.type
      if (type == 1) {
        this.active = "board"
      } else if (type == 2) {
        this.active = "level"
      }
    },

    methods: {}
  }

</script>
<style lang="scss" scoped>
  .Foundation {
    .top-banner {
      // height: 230px;
      border-radius: 5px;
      /* background: url("../../../assets/images/donation/donation_bg.png") no-repeat; */
      /* background: url("../../../assets/images/public/big_banner.jpg") no-repeat; */
      /* background-size: cover; */
      background-color: rgb(66, 102, 176);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      position: relative;

      .small_banner {
        height: 100%;
        position: absolute;
        // left: 0;
      }

      >h3 {
        color: #fff;
        font-size: 30px;
        margin-bottom: 37px;
      }

      >.el-button {
        /* width: 100px; */
      }
    }
  }
  @media (min-width: 600px) {
    .top-banner {
      height: 230px;
      .small_banner {
        left: 70px;
      }
    }
  }
  @media (max-width: 600px) {
    .top-banner{
      height: 180px;
      h3{
        font-size: 16px !important;
      }
      .small_banner {
        width: 70px;
        left: 25px;
      }
    }
  }
</style>