<template>
  <section>
    <div class="single-modal">
      <p v-html="level_description">{{level_description}}</p>
    </div>
    <div class="single-modal">
      <el-row v-for="(v,i) in level" :key="i" class="person-card">
        <el-col :span="3">
          <el-avatar style="width: 80px; height: 80px" :src="v.level_photo"></el-avatar>
        </el-col>
        <el-col :span="21">
          <div class="intro">
            <h3>{{v.level_name}}</h3>
            <span>US ${{v.level_rules}} ({{v.level_recognition}})</span>
            <p class="m-0" v-html="v.level_introduction">{{v.level_introduction}}</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        level_description: '',
        level: []
      };
    },

    components: {},

    created() {
      this._getDonorLevel()
    },

    methods: {
      async _getDonorLevel() {
        let res = await this.$http.getDonorLevel()
        if (res.status == 200) {
          this.level_description = res.data.level_description
          this.level = res.data.level
        }
      }
    }
  }

</script>
<style lang="scss" scoped>
  .single-modal {
    >h3 {
      color: #333;
      font-size: 14px;
      padding: 10px 0 20px;
      font-weight: 500;
    }

    ::v-deep p {
      color: #666;
      width: 100%;
      /* word-break: break-all; */
      /* word-wrap: break-word; */
    }

    .person-card {
      border: 1px solid #f1f1f1;
      background: #fbfbfb;
      padding: 16px;
      margin-bottom: 20px;
      border-radius: 5px;

      .intro {
        >h3 {
          font-size: 16px;
          color: #333;
        }

        >span {
          font-size: 12px;
          color: #999;
        }

        p {
          color: #666;
        }
      }
    }
  }
</style>